<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div>
    <validation-observer ref="simpleRules">
      <!-- รอดึงข้อมูลสำเร็จ -->
      <b-overlay
        :show="showOver"
        variant="transparent"
        opacity="0.99"
        blur="5px"
        rounded="sm"
      >
        <template #overlay>
          <div class="text-center">
            <p id="cancel-label">
              กรุณารอสักครู่...
            </p>
          </div>
        </template>
        <!-- รอดึงข้อมูลสำเร็จ -->
        <b-row class="match-height">
          <b-col
            lg="6"
            md="12"
          >

            <div class="card">
              <div class="header-cardx p-2">
                <div class="d-flex align-items-center justify-content-between">
                  <h3 class="mb-0">
                    {{ $t('warehouseInfo') }}
                  </h3>
                  <button
                    class="btn btn-gradient-primary"
                    @click="Submites()"
                  >
                    {{ $t('saveData') }}
                  </button>
                </div>

              </div>

              <div class="p-2">
                <b-row>
                  <b-col md="12">
                    <b-form-group
                      label-for="h-name"
                      label-cols="3"
                      label-cols-lg="3"
                      label-cols-md="3"
                    >
                      <template v-slot:label>
                        {{ $t('firstName') }} :
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="name"
                        rules="required"
                      >
                        <b-form-input
                          id="h-name"
                          v-model="name"
                          :state="errors.length > 0 ? false : null"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                      label-for="h-width"
                      label-cols="3"
                      label-cols-lg="3"
                      label-cols-md="3"
                    >
                      <template v-slot:label>
                        {{ $t('widthCm') }} :
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="width"
                        rules="required"
                      >
                        <b-input-group class="input-group-merge">
                          <b-form-input
                            id="h-width"
                            v-model="width"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            placeholder=""
                          />
                          <b-input-group-append is-text>
                            {{ $t('cm') }}
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                      label-for="h-height"
                      label-cols="3"
                      label-cols-lg="3"
                      label-cols-md="3"
                    >
                      <template v-slot:label>
                        {{ $t('heightCm') }} :
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="height"
                        rules="required"
                      >
                        <b-input-group class="input-group-merge">
                          <b-form-input
                            id="h-height"
                            v-model="height"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            placeholder=""
                          />
                          <b-input-group-append is-text>
                            {{ $t('cm') }}
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                      label-for="h-long"
                      label-cols="3"
                      label-cols-lg="3"
                      label-cols-md="3"
                    >
                      <template v-slot:label>
                        {{ $t('lengthCm') }} :
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="long"
                        rules="required"
                      >
                        <b-input-group class="input-group-merge">
                          <b-form-input
                            id="h-long"
                            v-model="long"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            placeholder=""
                          />
                          <b-input-group-append is-text>
                            {{ $t('cm') }}
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                      label-for="example-map"
                      label-cols="3"
                      label-cols-lg="3"
                      label-cols-md="3"
                    >
                      <template v-slot:label>
                        {{ $t('map') }} :
                      </template>
                      <b-form-file
                        id="example-map"
                        v-model="fileMap"
                        :placeholder="$t('uploadFile')"
                        class="w-100"
                        accept=".jpg, .png, .jpeg"
                        @change="SubmitfileMap"
                      />
                      <!-- {{ namemap }} -->
                      <img
                        v-if="namemap"
                        :src="namemap"
                        accept=".jpg, .png, .jpeg"
                        alt="รูปภาพ"
                        class="aws-avatar aws aws-avatar-square aws-avatar-image bordered cursor-pointer"
                      >
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                      label-for="h-section"
                      label-cols="3"
                      label-cols-lg="3"
                      label-cols-md="3"
                    >
                      <!-- {{ section }} -->
                      <template v-slot:label>
                        {{ $t('floor') }} :
                      </template>
                      <v-select
                        id="h-section"
                        v-model="section"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="text"
                        :options="sectionlist"
                        class="w-25 mr-1"
                        :reduce="text => text.text"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                      label-for="h-cargo"
                      label-cols="3"
                      label-cols-lg="3"
                      label-cols-md="3"
                    >
                      <!-- {{ cargo }} -->
                      <template v-slot:label>
                        {{ $t('warehouseNumber') }} :
                      </template>
                      <v-select
                        id="h-cargo"
                        v-model="cargo"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="text"
                        :options="cargolist"
                        class="w-25 mr-1"
                        :reduce="text => text.value"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-overlay>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormFile,
  BOverlay,
  // รอดึงข้อมูลสำเร็จ
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import vSelect from 'vue-select'

import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  components: {
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    vSelect,
    BInputGroup,
    BInputGroupAppend,
    BFormFile,
    BOverlay,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      required,
      email,
      name: null,
      width: null,
      height: null,
      long: null,
      fileMap: null,
      section: null,
      cargo: null,
      namemap: null,
      sectionlist: [
        { text: '1' },
        { text: '2' },
        { text: '3' },
        { text: '4' },
        { text: '5' },
        { text: '6' },
        { text: '7' },
      ],
      cargolist: [
        { text: 'โกดัง 1', value: 1 },
        { text: 'โกดัง 2', value: 2 },
      ],
      ID: this.$route.params.id,
      showOver: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
  },
  mounted() {
  },
  methods: {
    Submites() {
      const obj = {
        // agent_id: Number, // ไอดีเอเจนต์
        agent_id: null,
        name: this.name, // ชื่อคลัง
        wide_size: this.width, // ขนาดกว้าง
        long_size: this.long, // ขนาดยาว
        high_size: this.height, // ขนาดสูง
        layer: this.section, // ชั้น
        warehouse_at: this.cargo,
        map: this.namemap,
      }
      this.showOver = true
      this.$http.post('/warehouses/storewarehouse', obj)
        .then(() => {
          this.Success(this.$t('key-279'))
          this.$router.push({ name: 'admin-warehouse-section' })
          this.showOver = false
        })
        .catch(error => {
          console.log(error)
          // this.SwalError('')
          this.showOver = false
        })
    },

    SubmitfileMap(event) {
      // console.log(event)
      this.fileMap = event.target.files
      this.namemap = null
      const reader = new FileReader()
      const filepath = this.fileMap[0]
      reader.onload = e => {
        // console.log(e.target.result)
        this.namemap = e.target.result
        // console.log(this.namemap)
      }
      reader.readAsDataURL(filepath)
    },
    Submit() {
      this.$refs.simpleRules.validate().then(success => {
        if (success && this.group_use) {
          const subroleJs = this.subrole.filter(item => item.status)
          const subrole = subroleJs.map(item => item.level)
          const formData = {
            role: this.role.value,
            email: this.email,
            fullname: this.fullname,
            tel: this.tel,
            tel2: this.tel2,
            different: this.different,
            invited_by: this.invited_by,
            username: this.username,
            password: this.password,
            // sub_role: this.sub_role,
            sub_role: subrole.toString(),
            module_id: this.module_use.id,
            Address: this.Address,
            group_use: this.group_use.id,
            group_user_type: this.group_user_type,
            itemsCar: this.itemsCar,
            itemsShip: this.itemsShip,
          }
          // console.log(formData)
          this.$http
            .post('/users/Adminstore', formData)
            .then(() => {
              this.Success(this.$t('addUser'))
              this.role = null
              this.email = null
              this.fullname = null
              this.tel = null
              this.tel2 = null
              this.different = null
              this.invited_by = null
              this.username = null
              this.password = null
              this.$router.push({ name: 'admin-employee' })
            })
            .catch(error => {
              this.SwalError(error.response.data.message)
            })
        }
      })
    },
    CalModule() {
      const moduleArr = this.module_use.sub_role.split(',')
      this.subrole.forEach(item => {
        if (moduleArr.includes(item.level.toString())) {
          this.$set(item, 'status', true)
        } else {
          this.$set(item, 'status', false)
        }
      })
    },
    CalGroupUser() {
      if (this.group_use) {
        // eslint-disable-next-line
        for (const Index in this.itemsCar) {
          this.itemsCar[Index].kg = this.group_use[this.itemsCar[Index].key_kg]
          this.itemsCar[Index].queue = this.group_use[this.itemsCar[Index].key_q]
        }
        // eslint-disable-next-line
        for (const Index in this.itemsShip) {
          this.itemsShip[Index].kg = this.group_use[this.itemsShip[Index].key_kg]
          this.itemsShip[Index].queue = this.group_use[this.itemsShip[Index].key_q]
        }
      }
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-219')}!</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    showAdd() {
      this.$bvModal.show('add-addr')
    },
    showModal() {
      this.name = null
      this.phone = null
      this.address = null
      this.province = null
      this.district = null
      this.subdistrict = null
      this.postalcode = null
      this.$bvModal.show('modal-1')
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
